import * as React from 'react';
import { Link } from 'gatsby';
import { Container } from 'react-bootstrap';
import Layout from '../components/layout';
import Seo from '../components/seo';

const Disclaimer = () => {
  return (
    <Layout>
      <Container className="my-3">
        <Seo title="著作権について" />
        <h1 className="h2">著作権について</h1>
        <div className="my-5">
          <div className="my-2">当ブログで掲載している文章や画像などにつきましては、無断転載することを禁止します。</div>
          <div className="my-2">当ブログは著作権や肖像権の侵害を目的としたものではありません。著作権や肖像権に関して問題がございましたら、お問い合わせフォームよりご連絡ください。迅速に対応いたします。</div>
        </div>
        <div className="mt-5 text-center">
          <Link to="/" className="text-secondary text-decoration-none">記事一覧に戻る</Link>
        </div>
      </Container>
    </Layout>
  )
}

export default Disclaimer;